<template>
  <div class="default">
    <h1 class="title">Dtone.me</h1>
    <div class="main">
      <ul>
        <li v-for="(it, idx) in items" :key="idx">
          <div class="item-in">
            <div class="item-top">
              <img :src="it.icon" alt="" class="left"/>
              <div>
                <p class="item-name">{{it.name}}</p>
                <p>
                  <img class="item-stars" src="../assets/img/stars.png" alt="" />
                  <span>({{it.starCnt}})</span>
                </p>
              </div>
            </div>
            
            <div class="item-desc">{{it.desc}}</div>
            <div class="item-btn">
              <a class="item-btn-in" v-if="it.btnLink" :href="it.btnLink" target="_blank" :style="it.btnStyle">Visit Site</a>
              <template  v-else>
                <a :href="it.btnIosLink" class="item-btn-left" target="_blank"><img src="../assets/img/appstore.png" alt="" /></a>
                <a :href="it.btnIosLink" class="item-btn-right" target="_blank"><img src="../assets/img/googleplay.png" alt="" /></a>
              </template>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- <p class="footer">© 2021 Dingtone Communications Ltd</p> -->
  </div>
</template>

<script>
export default {
  name: 'default',
  props: {
    msg: String
  },
  data() {
    return {
      items: [
        {
          icon: require('../assets/img/skyvpn.png'),
          name: 'SkyVPN',
          starCnt: '200k+',
          desc: 'SkyVPN is an ultrafast VPN proxy server that gives you a free access to unblock websites and bypass school Wi-Fi with just one tap.',
          btnStyle: 'background-color:#1F79FF;',
          btnLink: 'https://www.skyvpn.net/'
        },
        {
          icon: require('../assets/img/2ndLine.png'),
          name: '2nd Line',
          starCnt: '40k+',
          desc: '2nd Line is a second phone number system for Android device! It gives you a 2nd phone number as a sideline on your smartphone to let you call and text for personal and for work separately.',
          btnStyle: 'background-color:#03AF87;',
          btnLink: 'http://2ndline.net/'
        },
        {
          icon: require('../assets/img/fastyle.png'),
          name: 'Fastyle',
          starCnt: '200k+',
          desc: "It's time to get into healthier habits and lose weight using the Fastyle Intermittent Fasting App! Backed by top nutrition and lifestyle doctors, this fasting tracker free to download will help you track your body status and keep you on schedule.",
          btnStyle: 'background-color:#23E1C6;',
          btnLink: 'https://www.fastyle.me/'
        },
        {
          icon: require('../assets/img/telos.png'),
          name: 'Telos',
          starCnt: '60k+',
          desc: 'Telos is a 2nd phone number app to protect your privacy. Choose a free phone number for unlimited call and text now!',
          btnStyle: 'background-color:#008EF0;',
          btnLink: 'https://www.telosapp.com/'
        },
        {
          icon: require('../assets/img/blockcall.png'),
          name: 'Block Call',
          starCnt: '1k+',
          desc: "Robocall Blocker is an effective way to end phone spams for good! It eliminates up to 98% of robocalls automatically. Robo Call Blocker is the only call filter you'll ever need to block spam calls & scam calls! The database of spam call blocker analyzes calls instantly and shows you caller ID spam alerts.",
          btnIosLink: 'https://apps.apple.com/us/app/id1570764921',
          btnAntLink: 'https://play.google.com/store/apps/details?id=me.spam.robo.call.blocker.call.filter.app'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}
.default {
  background-color: #F0F3F6;
}

.title {
  display: inline-block;
  line-height: 88px;
  padding-left: 32px;

  font-size: 32px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #1E1A1F;
}

.main {
  padding: 24px 32px 40px 32px;
}
li {
  padding-bottom: 24px;
}
.item-in {
  padding: 40px;
  border-radius: 12px;
  background: #FFFFFF;
  background-color: white;

  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(30, 26, 31, 0.7);
  line-height: 33px;
}

.item-name {
  font-size: 36px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #1E1A1F;
  line-height: 42px;
  vertical-align: middle;
}

.item-top{
  display: flex;
} 
.item-top .left {
  width: 160px;
}
.item-top .left img {
  width: auto;
  height: 120px;
}
.item-top p {
  height: 80px;
  line-height: 80px;
  padding-left: 40px;
}
.item-top p img {
  display: inline-block;
  margin-right: 16px;
  height: 40px;
  width: auto;

  vertical-align: middle;
}
.item-top p span {
  display: inline-block;
  vertical-align: middle;

  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1E1A1F;
  line-height: 33px;
}
.item-desc {
  margin-top: 28px;

  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(30, 26, 31, 0.7);
  line-height: 33px;
}

.item-btn {
  margin-top: 40px;
  text-align: center;
}
.item-btn-in {
  display: block;
  height: 88px;

  line-height: 88px;
  text-align: center;
  background: #1F79FF;
  border-radius: 8px;
  font-size: 36px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #FFFFFF;
}
.item-btn-left,.item-btn-right {
  display: inline-block;
  background-color: #000000;
  width: 288px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  vertical-align: middle;
  border-radius: 8px;
}
.item-btn-left img,.item-btn-right img {
  display: inline-block;
  height: 54px;
  width: auto;
  line-height: 88px;
  vertical-align: middle;
}
.item-btn-left {
  margin-right: 20px;
}


.footer {
  height: 72px;
  line-height: 72px;

  vertical-align: middle;
  background: #FFFFFF;
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(30, 26, 31, 0.5);
  text-align: center;
}
</style>
