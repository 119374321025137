<template>
  <div id="app">
    <Default/>
  </div>
</template>

<script>
import Default from './components/default.vue'

export default {
  name: 'App',
  components: {
    Default
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
ul {
  list-style: none;
}
</style>
